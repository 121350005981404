//
//
//
//

    import BScroll from 'better-scroll'
    export default {
        name: 'scroll',
        props: {
            probeType: {
                type: Number,
                default: 3
            },
            click: {
                type: Boolean,
                default: true
            },
            listenScroll: {
                type: Boolean,
                default: false
            },
            data: {
                type: Array,
                default: null
            },
            pullup: {
                type: Boolean,
                default: false
            },
            beforeScroll: {
                type: Boolean,
                default: false
            },
            refreshDelay: {
                type: Number,
                default: 20
            }
        },
        mounted () {
            setTimeout(() => {
                this._initScroll()
            }, this.refreshDelay)
        },
        methods: {
            _initScroll () {
                if (!this.$refs.scrollWrapper) {
                    return
                }
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.scrollWrapper, {
                        probeType: this.probeType,
                        click: this.click
                    })
                    // 获取滚动数据
                    if (this.listenScroll) {
                        this.scroll.on('scroll', pos => {
                            this.$emit('scroll', pos)
                        })
                    }
                    // 滚动到底时候
                    if (this.pullup) {
                        this.scroll.on('scrollEnd', () => {
                            if (this.scroll.y <= (this.scroll.maxScrollY + 50)) {
                                this.$emit('scrollToEnd')
                            }
                        })
                    }
                    // 开始滚动前
                    if (this.beforeScroll) {
                        this.scroll.on('beforeScrollStart', () => {
                            this.$emit('beforeScroll')
                        })
                    }
                }
            },
            // 禁用
            disable () {
                this.scroll && this.scroll.disable()
            },
            // 开启
            enable () {
                this.scroll && this.scroll.enable()
            },
            // 刷新
            refresh () {
                this.scroll && this.scroll.refresh()
            },
            // 滚动到指定位置
            scrollTo () {
                this.scroll && this.scroll.scrollTo.apply(this.scroll, arguments)
            },
            // 滚动到指定DOM
            scrollToElement () {
                this.scroll && this.scroll.scrollToElement.apply(this.scroll, arguments)
            }
        },
        watch: {
            data () {
                setTimeout(() => {
                    this.refresh()
                }, this.refreshDelay)
            }
        }
    }
