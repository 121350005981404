//
//
//
//
//
//
//
//
//
//

    import scroll from './scroll'
    export default {
        name: 'popup-picker-component',
        props: {
            popupTypeText: {
                type: String,
                default: '请选择'
            },
            subText: {
                type: String,
                default: ''
            },
            showPicker: {
                type: Boolean,
                default: false
            },
            results: {
                type: Array,
                required: true
            },
            position: {
                type: String,
                default: 'right'
            }
        },
        data () {
            return {
                currentIndex: null
            }
        },
        mounted() {
            console.log(this.results)
        },
        watch: {
            showPicker () {
                setTimeout(() => {
                    this.$refs.scrollWrapper.refresh()
                }, 20)
            }
        },
        methods: {
            selectHandle (index) {
                this.currentIndex = index
            },
            popupHandle () {
                this.$emit('popupHandle')
            },
            submitHandle (item) {
                this.$emit('submitHandle', item)
            }
        },
        components: {
            scroll
        }
    }
